// extracted by mini-css-extract-plugin
export var codeBlock = "CodeBlock-module--code-block--1KHcS";
export var dollars = "CodeBlock-module--dollars--1GS9x";
export var cropped = "CodeBlock-module--cropped--1Mkj1";
export var numbered = "CodeBlock-module--numbered--3PgL4";
export var digits1 = "CodeBlock-module--digits-1--2W-SS";
export var digits2 = "CodeBlock-module--digits-2--28TJJ";
export var digits3 = "CodeBlock-module--digits-3--3bUq9";
export var macosWindow = "CodeBlock-module--macos-window--35zZI";
export var macosTopbar = "CodeBlock-module--macos-topbar--1YIsZ";
export var macosMenu = "CodeBlock-module--macos-menu--3RNa9";
export var macosCircle1 = "CodeBlock-module--macos-circle-1--2tW-A";
export var macosCircle2 = "CodeBlock-module--macos-circle-2--2h7tr";
export var macosCircle3 = "CodeBlock-module--macos-circle-3--2kyUC";
export var codeWrapper = "CodeBlock-module--code-wrapper--2KROD";
export var copyBtn = "CodeBlock-module--copy-btn--4HZWA";
export var small = "CodeBlock-module--small--29zbg";